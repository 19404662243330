import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-stocks/"
            }}>{`All-Stars Stocks`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-round-robin/"
            }}>{`Round Robin`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-weekly/"
            }}>{`All-Stars Weekly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-stocks-on-a-stroll/"
            }}>{`Stocks on a Stroll`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: aggressive growth`}</li>
        <li parentName="ul">{`Type: momentum strategy`}</li>
        <li parentName="ul">{`Invests in: individual stocks, bond ETFs`}</li>
        <li parentName="ul">{`Rebalancing schedule: Weekly`}</li>
        <li parentName="ul">{`Taxation: 90% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $30,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`Stocks on the Loose`}</em>{` aims to continuously beat the S&P 500 while at the same time offering lower volatility and lower drawdowns. The strategy is a proprietary expansion upon Clenow’s `}<a parentName="p" {...{
          "href": "/portfolios/clenow-stocks-on-the-move/"
        }}>{`Stocks on the Move`}</a>{`, featuring a momentum approach combined with fixed-fraction position-sizing and managed bonds as a risk-off investment. With its weekly rebalancing schedule, `}<em parentName="p">{`Stocks on the Loose`}</em>{` has only moderate maintenance requirements and appeals to investors seeking to outperform the market at lower risk.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Stocks on the Loose: A high-powered stock strategy",
                "title": "TuringTrader.com, Stocks on the Loose: A high-powered stock strategy",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/improving-momentum-strategies/"
            }}>{`Improving Momentum Strategies`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/market-vane/"
            }}>{`Market Vane Indicator`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/clenow-stocks-on-the-move/"
            }}>{`Clenow's Stocks on the Move`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Stocks on the Loose`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`trade all S&P 100 stocks, plus U.S. treasuries as a risk-off asset`}</li>
        <li parentName="ul">{`rebalance once per week (on Wednesdays)`}</li>
        <li parentName="ul">{`disqualify stocks trading below their 100-day moving average`}</li>
        <li parentName="ul">{`disqualify stocks that made any single-day moves exceeding 10% in the past 90 days`}</li>
        <li parentName="ul">{`rank stocks by their volatility-adjusted momentum, calculated as the product of slope and R`}<sup>{`2`}</sup>{` of a 90-day logarithmic regression`}</li>
        <li parentName="ul">{`only open new positions, if TuringTrader's `}<em parentName="li">{`Market Vane`}</em>{` indicates bullish conditions`}</li>
        <li parentName="ul">{`use fixed-fractional position sizing, based on the 20-day average trading range`}</li>
        <li parentName="ul">{`ensure healthy position sizes by limiting total portfolio risk, capping the maximum allocation to a single stock, and scaling back exposure with increasing market volatility`}</li>
        <li parentName="ul">{`invest any unused capital in TuringTrader's `}<em parentName="li">{`Buoy`}</em>{` portfolio`}</li>
      </ul>
      <p>{`Most of these rules are taken verbatim from Clenow's `}<em parentName="p">{`Stocks on the Move`}</em>{` strategy. We recommend reading Clenow's book to understand the strategy's rationale better.`}</p>
      <p>{`Expanding upon Clenow's work, we improved the money management to remedy the following issues: concentration in too few assets, taking excessive total portfolio risk, and holding idle cash. These proprietary changes lead to a much more even-keeled behavior and significantly higher returns.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Stocks on the Loose`}</em>{` typically allocates 70% to 100% of its capital towards a set of 3 to 5 stocks. In more turbulent times, the strategy rotates into a managed bond portfolio.`}</p>
      <p>{`With this allocation, the portfolio bears significant concentration risk, in addition to the market risk. Nonetheless, the portfolio's beta is around 0.3, thanks to the strategy's timely active management.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Over the entire economic cycle, `}<em parentName="p">{`Stocks on the Loose`}</em>{` outperforms the S&P 500 by a wide margin. Further, the portfolio beats the S&P 500 in many years, even outside of recessions.`}</p>
      <p>{`This behavior results in more predictable returns and lower drawdowns. The Monte-Carlo simulation confirms these claims.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`Stocks on the Loose`}</em>{` trades frequently and regularly triggers taxable events. Investors should expect almost all capital gains to be short-term. Therefore, the strategy works best in tax-deferred accounts.`}</p>
      <p>{`Because the strategy holds up to 6 high-flying and potentially expensive stocks simultaneously, it requires an account holding no less than $30,000 to function as intended.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, May 2020`}</a>{`: Initial release, expanding upon `}<a parentName="li" {...{
            "href": "/portfolios/clenow-stocks-on-the-move/"
          }}>{`Clenow's Stocks on the Move`}</a>{`.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=2"
          }}>{`v2, March 2021`}</a>{`: Improved strategy to use `}<a parentName="li" {...{
            "href": "/portfolios/tt-market-vane/"
          }}>{`Market Vane`}</a>{`; improved position-sizing.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=3"
          }}>{`v3, April 2022`}</a>{`: Improved strategy to use `}<a parentName="li" {...{
            "href": "/portfolios/tt-buoy/"
          }}>{`Buoy`}</a>{` as its risk-off asset.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      